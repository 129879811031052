import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../config/api';
import styled, { keyframes } from 'styled-components';
import { FiUploadCloud, FiDownload, FiShare2, FiTrash2, FiCopy, FiMaximize2 } from 'react-icons/fi';
import { 
  BsFiletypeJpg, 
  BsFiletypePdf, 
  BsFiletypeDocx, 
  BsFiletypeTxt, 
  BsFileEarmark 
} from 'react-icons/bs';
import { ClipLoader, PulseLoader } from 'react-spinners';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import { Button, Col, Row } from 'react-bootstrap';
import { motion, AnimatePresence } from 'framer-motion';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideUp = keyframes`
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  background: linear-gradient(135deg, ${({ theme }) => theme.bg} 0%, #1a2234 100%);
  min-height: 100vh;
  animation: ${fadeIn} 0.6s ease-in-out;
  
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 1rem;
    gap: 1.5rem;
  }
`;

const LeftPanel = styled.div`
  width: 48%;
  background: rgba(30, 40, 60, 0.8);
  border-radius: 16px;
  padding: 1.5rem;
  overflow-y: auto;
  max-height: 85vh;
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.18);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  animation: ${slideUp} 0.5s ease-out;
  
  @media (max-width: 768px) {
    width: 100%;
    order: 2;
    max-height: none;
    height: auto;
  }
`;

const RightPanel = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: ${slideUp} 0.5s ease-out 0.2s backwards;
  
  @media (max-width: 768px) {
    width: 100%;
    order: 1;
  }
`;

const UploadArea = styled.div`
  border: 2px dashed ${({ theme, isDragging }) => isDragging ? '#3498db' : theme.text_primary};
  border-radius: 20px;
  width: 100%;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  background: rgba(30, 40, 60, 0.4);
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-5px);
    background: rgba(40, 50, 70, 0.4);
    border-color: #3498db;
  }
`;

const UploadIcon = styled(FiUploadCloud)`
  font-size: 3.5rem;
  color: ${({ theme }) => theme.text_primary};
  margin-bottom: 1rem;
  transition: all 0.3s ease;
  
  ${UploadArea}:hover & {
    color: #3498db;
    transform: scale(1.1);
  }
`;

const UploadText = styled.p`
  color: ${({ theme }) => theme.text_primary};
  text-align: center;
  font-size: 1.1rem;
  max-width: 80%;
`;

const FileInput = styled.input`
  display: none;
`;

const FileItem = styled.div`
  background: rgba(40, 50, 70, 0.5);
  border-radius: 12px;
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all 0.25s ease;
  border: 1px solid transparent;

  &:hover {
    background: rgba(60, 70, 90, 0.5);
    transform: translateX(5px);
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  @media (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
    
    > div:last-child {
      align-self: flex-end;
    }
  }
`;

const FileDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const FileTypeIcon = styled.div`
  font-size: 1.8rem;
  color: ${({ type }) => {
    switch(type) {
      case 'image': return '#ff9f43';
      case 'pdf': return '#ee5253';
      case 'doc': return '#54a0ff';
      case 'text': return '#1dd1a1';
      default: return '#a5b1c2';
    }
  }};
`;

const FileInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const FileName = styled.span`
  color: ${({ theme }) => theme.text_primary};
  font-weight: 500;
  font-size: 0.95rem;
`;

const FileSize = styled.span`
  color: ${({ theme }) => theme.text_secondary};
  font-size: 0.8rem;
  margin-top: 4px;
`;

const FileDate = styled.span`
  color: ${({ theme }) => theme.text_secondary};
  font-size: 0.8rem;
`;

const PreviewArea = styled.div`
  width: 100%;
  margin-top: 2rem;
  border-radius: 16px;
  padding: 1.5rem;
  overflow: hidden;
  background: rgba(30, 40, 60, 0.8);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.18);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);
`;

const ActionButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  width: 100%;
  
  @media (max-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 420px) {
    grid-template-columns: 1fr;
  }
`;

const StyledButton = styled(Button)`
  padding: 12px 16px;
  background: ${({ $type }) => {
    switch($type) {
      case 'primary': return 'linear-gradient(135deg, #3498db, #2980b9)';
      case 'success': return 'linear-gradient(135deg, #2ecc71, #27ae60)';
      case 'warning': return 'linear-gradient(135deg, #f39c12, #e67e22)';
      case 'danger': return 'linear-gradient(135deg, #e74c3c, #c0392b)';
      case 'info': return 'linear-gradient(135deg, #1abc9c, #16a085)';
      default: return 'linear-gradient(135deg, #3498db, #2980b9)';
    }
  }};
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
    filter: brightness(110%);
  }

  &:active {
    transform: translateY(1px);
  }
`;

const ButtonIcon = styled.span`
  font-size: 1.2rem;
`;

const PreviewImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
`;

const modalAnimation = keyframes`
  from { transform: scale(0.9); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: ${modalAnimation} 0.3s ease-out;
`;

const ModalPreview = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 1.5rem;
  border-radius: 12px;
  background: #f8f9fa;
`;

const ModalControlsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 1rem;
  gap: 12px;
  
  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 8px;
  }
  
  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

const ActionButton = styled.button`
  padding: 10px 18px;
  background: ${({ color }) => color || '#3498db'};
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
    filter: brightness(110%);
  }
  
  @media (max-width: 576px) {
    width: 100%;
    justify-content: center;
  }
`;

const PanelTitle = styled.h2`
  color: white;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  position: relative;
  display: inline-block;
  
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -8px;
    width: 40px;
    height: 3px;
    background: #3498db;
    border-radius: 2px;
  }
`;

const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 1rem;
  color: ${({ theme }) => theme.text_secondary};
  text-align: center;
`;

const FileActions = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  
  @media (max-width: 576px) {
    align-self: flex-end;
  }
`;

const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  content: {
    position: 'relative',
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 'auto',
    maxWidth: '90%',
    maxHeight: '90vh',
    width: '95%',
    maxWidth: '1000px',
    backgroundColor: '#fff',
    borderRadius: '16px',
    padding: '24px',
    boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
    border: 'none'
  }
};

const Server = () => {
  const [file, setFile] = useState(null);
  const [userFiles, setUserFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [preview, setPreview] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const navigate = useNavigate();
    
  useEffect(() => {
    checkToken();
    fetchUserFiles();
  }, []);

  const checkToken = async () => {

    try {
      const res = await api.post('/auth/check-token')
      if (res?.status === 0) {
        localStorage.removeItem('token');
        navigate('/');
      }
      return res?.data
    } catch (error) {
      console.log("Error in checkToken", error)
    }

  }

  const fetchUserFiles = async () => {
    setIsLoading(true);
    try {
      const response = await api.get('/storage/user-files');
      if (response?.data?.data?.files) {
        setUserFiles(response.data.data.files);
      } else {
        setUserFiles([]);
        console.warn('Unexpected response format:', response);
      }
    } catch (error) {
      console.error('Error fetching user files:', error);
      const errorMessage = error.response?.data?.message || 'Failed to fetch user files';
      toast.error(errorMessage);
      
      if (error.response?.status === 401) {
        localStorage.removeItem('token');
        navigate('/');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    
    if (selectedFile) {
      // Check file size (limit to 10MB as an example)
      if (selectedFile.size > 10 * 1024 * 1024) {
        toast.error('File size exceeds 10MB limit');
        return;
      }
      
      // Validate file type if needed
      const validFileTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf', 'text/plain'];
      if (!validFileTypes.includes(selectedFile.type)) {
        toast.warning('File type may not be supported for preview');
      }
      
      setFile(selectedFile);
      handleSubmit(selectedFile);
      previewFile(selectedFile);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFile = e.dataTransfer.files[0];
    setFile(droppedFile);
    handleSubmit(droppedFile);
    previewFile(droppedFile);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const previewFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreview(reader.result);
    };
  };

  const handleSubmit = async (selectedFile) => {
    if (!selectedFile) {
      toast.error('Please select a file');
      return;
    }

    setIsUploading(true);
    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const token = localStorage.getItem('token');
      await api.post('/storage/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        },
      });
      toast.success('File uploaded successfully');
      fetchUserFiles();
    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error('Failed to upload file');
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('token');
        navigate('/');
      }
    } finally {
      setIsUploading(false);
    }
  };

  const openFileModal = (file) => {
    setSelectedFile(file);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedFile(null);
  };

  const getFileIcon = (fileName) => {
    if (fileName.match(/\.(jpeg|jpg|gif|png|webp|bmp)$/i)) {
      return <BsFiletypeJpg />;
    } else if (fileName.match(/\.(pdf)$/i)) {
      return <BsFiletypePdf />;
    } else if (fileName.match(/\.(doc|docx)$/i)) {
      return <BsFiletypeDocx />;
    } else if (fileName.match(/\.(txt|md)$/i)) {
      return <BsFiletypeTxt />;
    } else {
      return <BsFileEarmark />;
    }
  };

  const getFileType = (fileName) => {
    if (fileName.match(/\.(jpeg|jpg|gif|png|webp|bmp)$/i)) {
      return 'image';
    } else if (fileName.match(/\.(pdf)$/i)) {
      return 'pdf';
    } else if (fileName.match(/\.(doc|docx)$/i)) {
      return 'doc';
    } else if (fileName.match(/\.(txt|md)$/i)) {
      return 'text';
    } else {
      return 'other';
    }
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const downloadFile = (fileUrl, fileName) => {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const deleteFile = async (fileId, event) => {
    event.stopPropagation(); // Prevent opening the modal
    
    if (window.confirm('Are you sure you want to delete this file?')) {
      try {
        await api.delete(`/storage/files/${fileId}`);
        toast.success('File deleted successfully');
        setUserFiles(userFiles.filter(file => file._id !== fileId));
        
        // Close modal if the deleted file is currently selected
        if (selectedFile && selectedFile._id === fileId) {
          closeModal();
        }
      } catch (error) {
        console.error('Error deleting file:', error);
        toast.error('Failed to delete file');
        
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token');
          navigate('/');
        }
      }
    }
  };

  return (
    <Container>
      <LeftPanel>
        <PanelTitle>Your Files</PanelTitle>
        {isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', padding: '2rem' }}>
            <PulseLoader color="#3498db" size={12} margin={6} />
          </div>
        ) : userFiles.length === 0 ? (
          <EmptyState>
            <BsFileEarmark style={{ fontSize: '3rem', marginBottom: '1rem', opacity: 0.5 }} />
            <p>No files yet. Upload your first file!</p>
          </EmptyState>
        ) : (
          <AnimatePresence>
            {userFiles.map((file, index) => (
              <motion.div
                key={file._id}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -20 }}
                transition={{ duration: 0.2, delay: index * 0.05 }}
              >
                <FileItem onClick={() => openFileModal(file)}>
                  <FileDetails>
                    <FileTypeIcon type={getFileType(file.fileName)}>
                      {getFileIcon(file.fileName)}
                    </FileTypeIcon>
                    <FileInfo>
                      <FileName>{file.fileName}</FileName>
                      <FileSize>{formatFileSize(file.fileSize || 0)}</FileSize>
                    </FileInfo>
                  </FileDetails>
                  <FileActions>
                    <FileDate>
                      {new Date(file.createdAt).toLocaleDateString()}
                    </FileDate>
                    <FiTrash2 
                      style={{ cursor: 'pointer', color: '#e74c3c', marginLeft: '8px' }}
                      onClick={(e) => deleteFile(file._id, e)}
                    />
                  </FileActions>
                </FileItem>
              </motion.div>
            ))}
          </AnimatePresence>
        )}
      </LeftPanel>
      <RightPanel>
        <UploadArea
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={() => document.getElementById('fileInput').click()}
          isDragging={isDragging}
        >
          {isUploading ? (
            <motion.div
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.3 }}
            >
              <ClipLoader color="#3498db" loading={isUploading} size={50} />
              <UploadText style={{ marginTop: '1rem' }}>Uploading your file...</UploadText>
            </motion.div>
          ) : (
            <>
              <UploadIcon />
              <UploadText>
                <strong>Drag & Drop</strong> your files here or <strong>click</strong> to browse
              </UploadText>
              <UploadText style={{ fontSize: '0.8rem', opacity: 0.7, marginTop: '8px' }}>
                Supports images, PDFs, documents and more
              </UploadText>
            </>
          )}
        </UploadArea>
        <FileInput
          id="fileInput"
          type="file"
          onChange={handleFileChange}
        />
        <PreviewArea>
          <PanelTitle style={{ marginBottom: '1.2rem' }}>File Operations</PanelTitle>
          <ActionButtonsContainer>
            <StyledButton $type="primary">
              <ButtonIcon><FiMaximize2 /></ButtonIcon>
              Connect SSH
            </StyledButton>
            <StyledButton $type="info">
              <ButtonIcon><FiShare2 /></ButtonIcon>
              Send via Email
            </StyledButton>
            <StyledButton $type="success">
              <ButtonIcon><FiUploadCloud /></ButtonIcon>
              Start Socket Server
            </StyledButton>
            <StyledButton $type="primary">
              <ButtonIcon><FiUploadCloud /></ButtonIcon>
              Deploy on Server
            </StyledButton>
            <StyledButton $type="warning">
              <ButtonIcon><FiCopy /></ButtonIcon>
              Transfer to Server
            </StyledButton>
            <StyledButton $type="danger">
              <ButtonIcon><FiTrash2 /></ButtonIcon>
              Scan for Viruses
            </StyledButton>
          </ActionButtonsContainer>
        </PreviewArea>
      </RightPanel>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="File Preview Modal"
        style={modalStyles}
      >
        {selectedFile && (
          <ModalContent>
            <ModalPreview>
              {selectedFile.fileUrl.match(/\.(jpeg|jpg|gif|png|webp|bmp)$/i) ? (
                <PreviewImage src={selectedFile.fileUrl} alt={selectedFile.fileName} />
              ) : selectedFile.fileUrl.match(/\.(pdf)$/i) ? (
                <iframe 
                  src={selectedFile.fileUrl} 
                  title={selectedFile.fileName} 
                  width="100%" 
                  height="100%" 
                  frameBorder="0" 
                />
              ) : (
                <div style={{ 
                  display: 'flex', 
                  flexDirection: 'column', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  height: '100%'
                }}>
                  <FileTypeIcon type={getFileType(selectedFile.fileName)} style={{ fontSize: '4rem', marginBottom: '1rem' }}>
                    {getFileIcon(selectedFile.fileName)}
                  </FileTypeIcon>
                  <p>Preview not available for this file type.</p>
                  <p>Download to view the file.</p>
                </div>
              )}
            </ModalPreview>
            <h3>{selectedFile.fileName}</h3>
            <p>Uploaded on: {new Date(selectedFile.createdAt).toLocaleString()}</p>
            
            <ModalControlsContainer>
              <ActionButton 
                color="#3498db"
                onClick={() => window.open(selectedFile.fileUrl, '_blank')}
              >
                <FiMaximize2 /> Open in New Tab
              </ActionButton>
              <ActionButton 
                color="#2ecc71"
                onClick={() => downloadFile(selectedFile.fileUrl, selectedFile.fileName)}
              >
                <FiDownload /> Download
              </ActionButton>
              <ActionButton 
                color="#f39c12"
              >
                <FiShare2 /> Share
              </ActionButton>
              <ActionButton 
                color="#e74c3c" 
                onClick={closeModal}
              >
                Close
              </ActionButton>
            </ModalControlsContainer>
          </ModalContent>
        )}
      </Modal>
      <ToastContainer position="bottom-right" />
    </Container>
  );
};

export default Server;