import { ThemeProvider } from "styled-components";
import { useState, lazy, Suspense } from "react";
import { darkTheme, lightTheme } from './utils/Themes.js'
import Navbar from "./components/Navbar";
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import HeroSection from "./components/HeroSection";
import Skills from "./components/Skills";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Experience from "./components/Experience";
import Education from "./components/Education";
import ProjectDetails from "./components/ProjectDetails";
import styled from "styled-components";
import Secret from "./components/Secret/Secret.js";
import 'bootstrap/dist/css/bootstrap.min.css';
import MainContent from "./components/MainContent/MainContent.js";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Server from "./components/Test/Server.js";

// Lazy load AdminApp
const AdminApp = lazy(() => import('./admin/AdminApp'));

const Body = styled.div`
  background-color: ${({ theme }) => theme.bg};
  width: 100%;
  overflow-x: hidden;
`

const Wrapper = styled.div`
  background: linear-gradient(38.73deg, rgba(204, 0, 187, 0.15) 0%, rgba(201, 32, 184, 0) 50%), linear-gradient(141.27deg, rgba(0, 70, 209, 0) 50%, rgba(0, 70, 209, 0.15) 100%);
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%,30% 98%, 0 100%);
`

function App() {
  const [darkMode, setDarkMode] = useState(true);
  const [openModal, setOpenModal] = useState({ state: false, project: null });

  // Main site layout wrapper
  function LayoutWrapper({children}) {
    return (
      <>
        <Navbar darkMode={darkMode} setDarkMode={setDarkMode} />
        {children}
      </>
    );
  }

  // Main portfolio homepage
  const HomePage = () => (
    <LayoutWrapper>
      <Body>
        <HeroSection />
        <Wrapper>
          <Skills />
          <Experience />
        </Wrapper>
        <Projects openModal={openModal} setOpenModal={setOpenModal} />
        <Wrapper>
          <Education />
          <Contact />
        </Wrapper>
        <Footer />
        {openModal.state && <ProjectDetails openModal={openModal} setOpenModal={setOpenModal} />}
        <ToastContainer />
      </Body>
    </LayoutWrapper>
  );

  // Secret page
  const SecretPage = () => (
    <LayoutWrapper>
      <Secret />
    </LayoutWrapper>
  );

  // Server page
  const ServerPage = () => (
    <LayoutWrapper>
      <Server />
    </LayoutWrapper>
  );

  // All content page
  const AllContentPage = () => (
    <LayoutWrapper>
      <MainContent />
    </LayoutWrapper>
  );

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Router>
        <Routes>
          {/* Main portfolio routes */}
          <Route path="/" element={<HomePage />} />
          <Route path="/secret" element={<SecretPage />} />
          <Route path="/server" element={<ServerPage />} />
          <Route path="/allContent" element={<AllContentPage />} />
          
          {/* Admin routes - using AdminApp component */}
          <Route path="/admin/*" element={
            <Suspense fallback={<div>Loading Admin Panel...</div>}>
              <AdminApp />
            </Suspense>
          } />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
